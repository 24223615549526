import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from "react-helmet";
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../css/About.css';

function AboutPage() {
  return (
    <div className="about-big-wrapper">
      <Helmet htmlAttributes={{lang: 'sv'}}>
        <meta charSet="utf-8" />
        <title>Om Familjens kalender</title>
        <meta name="description" content="Lär dig mer om Familjens kalender, vad webbapplikation Familjens kalender kan användas för, varför den skapades och hur du kan hjälpa till med din feedback." />
      </Helmet>
      <div className="page-default-grid-layout">
        <header className="app-header">
          <div className="app-header-aside-left">
          </div>
          <div className="app-header-center">
            <h1>Familjens kalender</h1> 
            <h2>Skapa en egen personlig kalender</h2>
            <h3>Dela din personliga kalender med familj och vänner som PDF eller som utskrift</h3>
            <button className="app-header-button-click-me"><Link to="/kalender">Designa din kalender</Link></button>
            </div>  
          <div className="app-header-aside-right">
          </div>
        </header>   
        <nav className="app-nav">
          <Navigation />
        </nav>

        <main className="page-default-main">
          <h1>Om Familjens kalender</h1>
          <br/>
          <h2>Syfte</h2>
          <p>
            <i>Familjens kalender</i> är en webbapplikation med syftet att göra det lättare att skapa en egen personlig kalender. 
            Det kan vara för eget bruk eller kanske som en gåva till någon annan.
            Du kan bland annat välja egna bilder, lägga till födelsedagar och namnsdagar som du vill ska visas i kalendern. 
          </p>
          <br/>

          <h2>Bakgrund</h2>
          <p> 
            Min familj har en årlig tradition att plocka ut bilder tagna av familjen och skapa en egen personlig familjekalender med dem. 
            Det har varit lite olika teman på kalendrarna, till exempel bilder från när vi var små eller höjdpunkter från det tidigare året.
          </p>
          <br />

          <p> 
            Kalendern hjälper till att påminna oss om det roliga vi varit med om och även de dagar då vi ska tänka lite extra på varandra, kanske någons födelsedag eller namnsdag.
            Att skapa en kalender till familjen tillsammans kan även vara en trevlig aktivitet där man kan gå igenom bilder och minnas tillbaka.  
          </p>
          <br />

          <p>
            Kalendern har också varit en uppskattad gåva till andra, speciellt vår farmor som uppskattar att få bilder att titta på under året
            och hjälp att hålla reda på olika datum. 
            Leta fram några bilder med trevliga minnen du haft tillsammans med någon och gör en kalender av det så kan det bli en fin personlig present. 
          </p>
          <br />

          <p>
            Att skapa en personlig familjekalender till familjen har inte alltid varit så lätt. Det blir mycket manuellt arbete om man inte har bra program. 
            Att lägga in namnsdagar, födelsedagar och rätt högtider eller temadagar på rätt datum tar tid då kalendern ändras mellan åren.
            Klippa in bilder och placera dem rätt i kalendern är också tidskrävande. Detta borde gå att göra på något bättre sätt och mer automatiskt. 
          </p>
          <br />

          <p>
            Eftersom jag kan en del programmering och det kunde vara ett kul projekt, så bestämde jag mig för att försöka bygga en applikation för att
            göra det lättare att skapa en personlig kalender. Denna kalenderapplikation är resultatet.
            Eftersom tanken var att skapa en kalender till familjen så fick hemsidan också namnet <strong><i>Familjens kalender</i></strong>.
          </p>
          <br />

          <p>
            Troligen kan det finnas flera som vill kunna skapa en egen personlig kalender, enkelt och utan kostnad, 
            så jag bestämde att även dela denna applikation och göra den tillgänglig på internet.
            Jag hoppas du kommer uppskatta och få nytta av <i>Familjens kalender</i> du också! 
          </p>
          <br/>

          <h2>Skapad av</h2>
            <p>
              <i>Familjens kalender</i> är utvecklad av mig Johan Wikström, utbildad civilingenjör inom datateknik men några års erfarenhet som IT-konsult. 
              Jag tar hand om tjänsten själv och försöker se till att tjänster är korrekt och fungerar. 
              Dock kan det finnas fel som jag inte lyckats hitta än eller fel som uppstår av att något ändras.
              Vad som ska finnas i en kalender kan till exempel ändras över tid så hittar du något fel så hjälp mig gärna då och meddela mig. Tack!
            </p> 
          <br/>

          <h2>Kontakt, önskemål och feedback</h2>
          <p>
            Om du har några önskemål på saker du skulle vilja ha med i din kalender eller några funktioner som inte finns idag,
            hittat något som är fel eller bara vill säga hej så kan du skicka ett
            mail till <strong>info@familjenskalender.se</strong> så försöker jag ta en titt på det. 
            Notera att detta är en tjänst jag tar hand om på min fritid så jag hoppas du förlåter mig om jag inte alltid svarar snabbt eller åtgärdar fel omedelbart. 
          </p>
          <br/>
        </main>
        <Footer/>
      </div>
    </div>
  )
}

export default AboutPage;